import { User } from 'fitify-types/src/types/user'
import { IRouteType } from 'fitify-ui-onboarding/src/types'

import { IDataState } from './state'

// !TODO = chore rename FLAG to remoteFlag
// !TODO = chore rename KEY to stateKey

const ONBOARDING_PAGES_ROUTES = {
  home: {
    path: '/',
    event: {
      name: 'onboarding_welcome',
      params: {
        theme: true,
      },
    },
  },
  gender: {
    path: '/onboarding/gender',
    flag: 'onboarding_gender_enabled',
    key: 'gender',
    event: {
      name: 'onboarding_gender',
      params: {
        theme: true,
      },
    },
    required: true,
  },
  name: {
    path: '/onboarding/name',
    flag: 'onboarding_name_enabled',
    key: 'displayName',
    event: 'onboarding_name',
  },
  goal: {
    path: '/onboarding/goal',
    flag: 'onboarding_goal_enabled',
    key: 'goal',
    event: 'onboarding_goal',
    required: true,
  },
  goalPitch: {
    path: '/onboarding/goal-pitch',
    flag: 'onboarding_sales_pitch_enabled',
    event: 'onboarding_goal_pitch',
    condition: (data: IDataState) => {
      if (data.gender === User.Profile.Gender.Female) {
        return (
          data.goal === User.Profile.Goals.LoseWeight ||
          data.goal === User.Profile.Goals.GetToned
        )
      }

      if (data.gender === User.Profile.Gender.Male) {
        return (
          data.goal === User.Profile.Goals.GainMuscle ||
          data.goal === User.Profile.Goals.LoseWeight
        )
      }

      return false
    },
  },
  body: {
    path: '/onboarding/body',
    flag: 'onboarding_body_type_enabled',
    key: 'body_type',
    event: 'onboarding_body_type',
    required: true,
  },
  area: {
    path: '/onboarding/area',
    flag: 'onboarding_problem_areas_enabled',
    key: 'problem_areas',
    event: 'onboarding_problem_areas',
    required: true,
  },
  previousExperience: {
    path: '/onboarding/previous-experience',
    flag: 'onboarding_previous_experience_enabled',
    key: 'previous_experience',
    event: 'onboarding_previous_experience',
  },
  fitness: {
    path: '/onboarding/fitness',
    flag: 'onboarding_fitness_enabled',
    key: 'fitness',
    event: 'onboarding_fitness',
    required: true,
  },
  confident: {
    path: '/onboarding/confidence',
    flag: 'onboarding_confident_enabled',
    key: 'confident',
    event: 'onboarding_confident',
  },
  fitnessPitch: {
    path: '/onboarding/fitness-pitch',
    flag: 'onboarding_sales_pitch_enabled',
    event: 'onboarding_fitness_pitch',
  },
  age: {
    path: '/onboarding/age',
    flag: 'onboarding_age_enabled',
    key: 'age',
    event: 'onboarding_age',
    required: true,
  },
  height: {
    path: '/onboarding/height',
    flag: 'onboarding_height_enabled',
    key: 'height',
    event: 'onboarding_height',
    required: true,
  },
  weight: {
    path: '/onboarding/weight',
    flag: 'onboarding_weight_enabled',
    key: 'weight',
    event: 'onboarding_weight',
    required: true,
  },
  goalWeight: {
    path: '/onboarding/goal-weight',
    flag: 'onboarding_goal_weight_enabled',
    key: 'goal_weight',
    event: 'onboarding_goal_weight',
    condition: (data: IDataState) =>
      data.goal === User.Profile.Goals.LoseWeight,
  },
  goalWeightPitch: {
    path: '/onboarding/goal-weight-progress',
    flag: 'onboarding_sales_pitch_enabled',
    event: 'onboarding_goal_weight_pitch',
    condition: (data: IDataState) =>
      data.weight &&
      data.goal_weight &&
      data.goal === User.Profile.Goals.LoseWeight &&
      data.weight > data.goal_weight,
  },
  knee: {
    path: '/onboarding/knee',
    flag: 'onboarding_knee_pain_enabled',
    key: 'max_impact',
    event: 'onboarding_knee_pain',
    required: true,
  },
  kneePain: {
    path: '/onboarding/knee-pain',
    flag: 'onboarding_sales_pitch_enabled',
    event: 'onboarding_knee_pain_pitch',
    condition: (data: IDataState) => data.max_impact !== 2,
  },
  fitnessTimeConsuming: {
    path: '/onboarding/fitness-time-consuming',
    flag: 'onboarding_fitness_time_consuming_enabled',
    key: 'fitness_time_consuming',
    event: 'onboarding_fitness_time_consuming',
  },
  typicalDay: {
    path: '/onboarding/typical-day',
    flag: 'onboarding_typical_day_enabled',
    key: 'typical_day',
    event: 'onboarding_typical_day',
  },
  workoutFrequency: {
    path: '/onboarding/workout-frequency',
    flag: 'onboarding_workout_frequency_enabled',
    key: 'workout_frequency',
    event: 'onboarding_workout_frequency',
    condition: (data: IDataState) =>
      data.previous_experience ===
      User.Profile.PreviousExperience.WorkoutRegularly,
  },
  pushups: {
    path: '/onboarding/pushups',
    flag: 'onboarding_push_up_count_enabled',
    key: 'push_up_count',
    event: 'onboarding_push_up_count',
  },
  walking: {
    path: '/onboarding/walking',
    flag: 'onboarding_walking_duration_enabled',
    key: 'walking_duration',
    event: 'onboarding_walking_duration',
  },
  habits: {
    path: '/onboarding/habits',
    flag: 'onboarding_bad_habits_enabled',
    key: 'bad_habits',
    event: 'onboarding_bad_habits',
  },
  energy: {
    path: '/onboarding/energy',
    flag: 'onboarding_energy_level_enabled',
    key: 'energy_level',
    event: 'onboarding_energy_level',
  },
  procrastination: {
    path: '/onboarding/procrastination',
    flag: 'onboarding_procrastination_enabled',
    key: 'procrastination',
    event: 'onboarding_procrastination',
  },
  idealWeight: {
    path: '/onboarding/ideal-weight',
    flag: 'onboarding_latest_ideal_weight_enabled',
    key: 'latest_ideal_weight',
    event: 'onboarding_latest_ideal_weight',
    condition: (data: IDataState) =>
      data.goal === User.Profile.Goals.LoseWeight,
  },
  sleep: {
    path: '/onboarding/sleep',
    flag: 'onboarding_sleep_duration_enabled',
    key: 'sleep_duration',
    event: 'onboarding_sleep_duration',
  },
  stress: {
    path: '/onboarding/stress',
    flag: 'onboarding_stress_enabled',
    key: 'stress',
    event: 'onboarding_stress',
  },
  workoutEnvironment: {
    path: '/onboarding/workout-environment',
    flag: 'onboarding_workout_environment_enabled',
    key: 'workout_environment',
    event: 'onboarding_workout_environment',
  },
  water: {
    path: '/onboarding/water',
    flag: 'onboarding_water_intake_enabled',
    key: 'water_intake',
    event: 'onboarding_water_intake',
  },
  motivation: {
    path: '/onboarding/motivation',
    flag: 'onboarding_motivation_enabled',
    key: 'motivation',
    event: 'onboarding_motivation',
  },
  motivationPitch: {
    path: '/onboarding/motivation-pitch',
    flag: 'onboarding_sales_pitch_enabled',
    event: 'onboarding_motivation_pitch',
    condition: (data: IDataState) =>
      data.motivation && data.motivation.length > 0,
  },
  motivationLevel: {
    path: '/onboarding/motivation-level',
    flag: 'onboarding_motivation_level_enabled',
    key: 'motivation_level',
    event: 'onboarding_motivation_level',
  },
  feelSupport: {
    path: '/onboarding/feel-support',
    flag: 'onboarding_feel_support_enabled',
    key: 'feel_support',
    event: 'onboarding_feel_support',
  },
  commitment: {
    path: '/onboarding/commitment',
    flag: 'onboarding_commitment_enabled',
    key: 'commitment',
    event: 'onboarding_commitment',
  },
  workoutLength: {
    path: '/onboarding/workout-length',
    flag: 'onboarding_workout_length_enabled',
    key: 'workout_length',
    event: 'onboarding_workout_length',
  },
  commitmentPitch: {
    path: '/onboarding/commitment-pitch',
    flag: 'onboarding_sales_pitch_enabled',
    event: 'onboarding_commitment_pitch',
  },
  sleepBetter: {
    path: '/onboarding/sleep-better',
    flag: 'onboarding_leading_sleep_better_enabled',
    key: 'leading_sleep_better',
    event: 'onboarding_leading_sleep_better',
  },
  improveHealth: {
    path: '/onboarding/improve-health',
    flag: 'onboarding_leading_improve_health_enabled',
    key: 'leading_improve_health',
    event: 'onboarding_leading_improve_health',
  },
  moreRelaxed: {
    path: '/onboarding/more-relaxed',
    flag: 'onboarding_leading_more_relaxed_enabled',
    key: 'leading_more_relaxed',
    event: 'onboarding_leading_more_relaxed',
  },
  pace: {
    path: '/onboarding/pace',
    flag: 'onboarding_plan_pace_enabled',
    key: 'plan_pace',
    event: 'onboarding_plan_pace',
  },
  workoutDays: {
    path: '/onboarding/workout-days',
    flag: 'onboarding_workout_days_enabled',
    key: 'workout_days',
    event: 'onboarding_workout_days',
  },
  plan: {
    path: '/onboarding/plan',
    event: 'onboarding_plan_summary',
  },
  subscription: {
    path: '/onboarding/subscription',
    event: 'onboarding_subscription',
  },
  success: {
    path: '/onboarding/success',
    event: 'onboarding_success',
  },
} as const

const APP_ROUTES = {
  gift: {
    path: '/gift',
    event: 'onboarding_gift',
  },
  giftSuccess: {
    path: '/gift/success',
    event: 'onboarding_gift_success',
  },
  login: {
    path: '/auth/login',
    event: 'onboarding_signin',
  },
  loginEmail: {
    path: '/auth/login/email',
    event: 'onboarding_signin_email',
  },
  passwordReset: {
    path: '/auth/login/password-reset',
    event: 'onboarding_login_password_reset',
  },
  signup: {
    path: '/auth/signup',
    event: 'onboarding_signup',
  },
  signupEmail: {
    path: '/auth/signup/email',
    event: 'onboarding_signup_email',
  },
  loadingPlan: {
    path: '/onboarding/loading-plan',
    event: 'onboarding_generator_progress',
  },
  redeem: {
    path: '/redeem',
    event: 'onboarding_redeem',
  },
  redeemSuccess: {
    path: '/redeem/success',
    event: 'onboarding_redeem_sucess',
  },
  isic: {
    path: '/isic',
    event: 'onboarding_isic',
  },
}

export type NavigationKeys = keyof typeof ONBOARDING_PAGES_ROUTES
export const ONBOARDING_PAGES = ONBOARDING_PAGES_ROUTES as {
  [key in NavigationKeys]: IRouteType<keyof IDataState>
}
export const ONBOARDING_PAGES_MAP = Object.entries(ONBOARDING_PAGES).map(
  (e) => e[1]
) as IRouteType<keyof IDataState>[]

export type AppKeys = keyof typeof APP_ROUTES
export const APP_PAGES = {
  ...APP_ROUTES,
} as {
  [key in AppKeys]: {
    path: string
    event: string
  }
}
export const APP_PAGES_MAP = Object.entries(APP_PAGES).map(
  (e) => e[1]
) as IRouteType<keyof IDataState>[]
