import { type User } from 'firebase/auth'
import { doc, getDoc, getFirestore, Timestamp } from 'firebase/firestore'

export interface ISubscriptionDb {
  active?: boolean
}

export interface IUserCoach {
  coach_id?: string
  calendly_invitee_uuid?: string
  top_match_coach_id: string
}

export interface IUserIsic {
  cardholder_name: string
  card_number: string
  card_type: 'ISIC' | 'ITIC'
  discount_id: string
  last_verified: Timestamp | null
  verification_id: number
}

export interface IUserProfile {
  ability: {
    cardio: number
    flexibility: number
    strength: number
  }
  registered: Timestamp
  profile: Record<string, string | boolean>
  subscription_hc?: ISubscriptionDb
  subscription?: ISubscriptionDb
  coach?: IUserCoach
  onboarding_completed: {
    workouts: boolean
    hc: boolean
  }
  isic?: IUserIsic
}

export const fetchUserProfile = async (user: User) => {
  try {
    const firestore = getFirestore()
    const userRef = doc(firestore, 'users', user.uid)
    const userSnap = await getDoc(userRef)

    if (userSnap.exists()) {
      const profileData = userSnap.data() as IUserProfile
      return profileData
    }
  } catch (error) {
    console.error(error)
  }

  return null
}
