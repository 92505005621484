import { useTheme } from '@emotion/react'
import {
  RemoteContext,
  RemoteConfig,
} from 'fitify-ui/src/hooks/useRemoteConfig'
import {
  getItem,
  setItem,
  clearItem,
} from 'fitify-ui-onboarding/src/utils/storage'
import { toBoolean } from 'fitify-utils/src/properties'
import { useRouter } from 'next/router'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { PlanInterval } from '../types'

import { usePromoEligibilityCheck } from './usePromoEligibilityCheck'

const ACTIVE_PROMO_STORE = '@fitify/subscription/activePromo'
const PLAN_TYPES = Object.values(PlanInterval)

export const usePromo = (): string | null => {
  const router = useRouter()
  const [activePromo, setActivePromo] = useState<string | null>(
    getItem('local', ACTIVE_PROMO_STORE)
  )
  const [isPromoDeactivated, setIsPromoDeactivated] = useState<boolean>(false)

  const config = useContext(RemoteContext)
  const theme = useTheme()
  const appPrefix = useMemo(() => (theme.hcV1 ? '_coach' : ''), [theme])

  const isUserEligible = usePromoEligibilityCheck()

  useEffect(() => {
    if (!router.isReady || isPromoDeactivated) return

    const queryPromo = router.query.promo as string
    const storedPromo = getItem('local', ACTIVE_PROMO_STORE)

    // Update active promo and local storage only if query promo is different and not empty
    if (queryPromo && queryPromo !== storedPromo) {
      setActivePromo(queryPromo)
      setItem('local', ACTIVE_PROMO_STORE, queryPromo)
    } else if (storedPromo) {
      setActivePromo(storedPromo)
    }
  }, [isPromoDeactivated, router])

  const promoCode = useMemo(() => {
    if (!activePromo || isPromoDeactivated) return null

    const promoEnabled = PLAN_TYPES.some((plan) =>
      toBoolean(config?.[`promo_${activePromo}_${plan}${appPrefix}_enabled`])
    )

    return promoEnabled ? activePromo : null
  }, [activePromo, isPromoDeactivated, config, appPrefix])

  const deactivatePromo = useCallback(() => {
    setIsPromoDeactivated(true)
    setActivePromo(null)
    const storedPromo = getItem('local', ACTIVE_PROMO_STORE)
    if (storedPromo) {
      clearItem('local', ACTIVE_PROMO_STORE)
    }
  }, [])

  useEffect(() => {
    if (!isUserEligible) {
      deactivatePromo()
    }
  }, [deactivatePromo, isUserEligible])

  return promoCode
}

export const useInitialPromo = (
  isUserLoggedIn: boolean,
  config: RemoteConfig
) => {
  const router = useRouter()
  const isUserEligible = usePromoEligibilityCheck()

  useEffect(() => {
    if (!router.isReady || !config || !isUserLoggedIn || !isUserEligible) return

    const queryPromo = router.query.promo as string
    const storedPromo = getItem('local', ACTIVE_PROMO_STORE)

    if (!queryPromo && !storedPromo) {
      const onboardingPromo = config['onboarding_promo']
      setItem('local', ACTIVE_PROMO_STORE, onboardingPromo)
    }
  }, [
    config,
    isUserEligible,
    isUserLoggedIn,
    router.isReady,
    router.query.promo,
  ])
}
