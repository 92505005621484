import { track } from '@amplitude/analytics-browser'
import { getAnalytics, logEvent as LogFirebaseEvent } from 'firebase/analytics'
import { User } from 'fitify-types/src/types/user'
import smartlookClient from 'smartlook-client'

export const logEvent = (name: string, params?: { [key: string]: any }) => {
  LogFirebaseEvent(getAnalytics(), name, params)
  smartlookClient.track(name, params || {})
  track(name, params || {})
}

export const logProviderClickEvent = (
  providerId: User.Auth.SignInMethod,
  flow: 'signup' | 'signin'
) => {
  logEvent('auth_provider_click', {
    provider_id: providerId,
    flow: flow,
  })
}
