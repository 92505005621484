import { ToastContainer, toast as toastify, ToastOptions } from 'react-toastify'

export type TOAST_TYPE = 'info' | 'warning' | 'success' | 'error'

interface ToastProps {
  type: TOAST_TYPE
  content: string
  options?: ToastOptions
}

export function toast({ type, content, options }: ToastProps) {
  const toastConfig = {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    theme: 'colored',
    ...options,
  } as ToastOptions

  switch (type) {
    case 'info':
      toastify.info(content, toastConfig)
      break
    case 'warning':
      toastify.warn(content, toastConfig)
      break
    case 'success':
      toastify.success(content, toastConfig)
      break
    case 'error':
      toastify.error(content, toastConfig)
      break
    default:
      toastify(content, toastConfig)
  }
}

export const Toast = () => {
  return <ToastContainer />
}
