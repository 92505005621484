import { UnitSystem } from 'fitify-types/src/types/common'
import {
  RemoteConfig,
  RemoteContext,
} from 'fitify-ui/src/hooks/useRemoteConfig'
import { getNavigatorLocale } from 'fitify-ui-onboarding'
import {
  useInitNavigation,
  useAuth,
  useAuthEvent,
  useInitialPromo,
  usePageEvent,
  usePreload,
  useStepValidation,
  useUserProperties,
} from 'fitify-ui-onboarding/src/hooks'
import { IMPERIAL_UNITS_LOCALES } from 'fitify-ui-onboarding/src/types'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { useEffect, useMemo } from 'react'

import { createUserProfile } from 'api/userProfile'
import { useUserRedirect } from 'hooks/useUserRedirect'
import {
  APP_PAGES_MAP,
  ONBOARDING_PAGES,
  ONBOARDING_PAGES_MAP,
} from 'utils/routes'

import { DataContext, useAppState } from '../../utils/state'

export function PlanApp({
  Component,
  pageProps,
  config,
}: Pick<AppProps, 'Component' | 'pageProps'> & { config: RemoteConfig }) {
  const router = useRouter()
  const { data, set, clear, isLoading } = useAppState()
  const { user, userProfile, isLoggedIn } = useAuth()

  useUserRedirect(userProfile)

  // Init navigation of onboarding pages and return allowed routes
  const allowedRoutes = useInitNavigation(ONBOARDING_PAGES_MAP, config)

  // Validates previous step
  useStepValidation(allowedRoutes, data, isLoading)

  // Logs page events
  useAuthEvent(user)
  useUserProperties(data, userProfile)
  usePageEvent([...ONBOARDING_PAGES_MAP, ...APP_PAGES_MAP])
  usePreload()

  // Initial promo
  useInitialPromo(isLoggedIn, config)

  // Set default imperial or metric based on locale
  useEffect(() => {
    if (!data.units) {
      const locale = getNavigatorLocale()
      const hasImperialUnits = IMPERIAL_UNITS_LOCALES.includes(locale)

      set({ units: hasImperialUnits ? UnitSystem.Imperial : UnitSystem.Metric })
    }
  }, [data.units, set])

  // Set initial data from user profile if it exists
  useEffect(() => {
    if (Object.keys(data).length <= 1 && userProfile?.profile) {
      set(userProfile?.profile)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userProfile])

  // Create user profile if it doesn't exist
  useEffect(() => {
    if (!isLoggedIn || userProfile?.onboarding_completed) return

    // Create user profile if data are valid and user is on allowed page
    const createProfilePages = [
      ONBOARDING_PAGES.plan.path,
      ONBOARDING_PAGES.subscription.path,
    ]

    if (createProfilePages.includes(router.route)) {
      // Check if all the data is available
      const onboardingKeys = Object.values(ONBOARDING_PAGES_MAP)
        .filter((page) => page.required)
        .map((page) => page.key)
      const dataKeys = Object.keys(data)
      const isDataValid = onboardingKeys.every(
        (value) => value && dataKeys.includes(value)
      )

      if (isDataValid) {
        createUserProfile(data)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router, isLoggedIn])

  const dataContextValue = useMemo(
    () => ({ data, set, clear }),
    [data, set, clear]
  )

  return (
    <RemoteContext.Provider value={config}>
      <DataContext.Provider value={dataContextValue}>
        <Component {...pageProps} userProfile={userProfile} />
      </DataContext.Provider>
    </RemoteContext.Provider>
  )
}
