import { useTheme } from '@emotion/react'
import { getAuth, onAuthStateChanged, signOut, type User } from 'firebase/auth'
import { RemoteContext } from 'fitify-ui/src/hooks/useRemoteConfig'
import { useRouter } from 'next/router'
import { ParsedUrlQuery } from 'querystring'
import { useContext, useEffect, useState } from 'react'

import { fetchUserProfile, IUserProfile } from '../api/user'
import { authHelper } from '../helpers'

export type UserAuthData = {
  user: User | null
  userProfile: IUserProfile | null
  isLoggedIn: boolean
  isAuthLoading: boolean
  logout: () => Promise<void>
}

// Custom hook for handling authentication
export function useAuth(): UserAuthData {
  const router = useRouter()
  const auth = getAuth()

  const [user, setUser] = useState<User | null>(null)
  const [userProfile, setUserProfile] = useState<IUserProfile | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    if (!router.isReady) return

    const unsubscribe = onAuthStateChanged(auth, async (authUser) => {
      if (authUser) {
        // Fetch user profile only if the user ID has changed
        if (authUser.uid !== user?.uid) {
          const profile = await fetchUserProfile(authUser)
          setUserProfile(profile)
        }
        setUser(authUser)
      } else {
        // Reset state if no user is authenticated
        setUser(null)
        setUserProfile(null)
      }
      setIsLoading(false)
    })

    return unsubscribe
  }, [user?.uid, auth, router.isReady])

  // Logout user
  const logout = async () => {
    await signOut(auth)
  }

  return {
    user,
    userProfile,
    isLoggedIn: !!user,
    isAuthLoading: isLoading,
    logout,
  }
}

export const useAuthRedirect = (isLoggedIn: boolean): void => {
  const router = useRouter()
  const theme = useTheme()
  const config = useContext(RemoteContext)

  // useEffect to handle the side-effect of redirection
  useEffect(() => {
    // Exit early if configuration, router, or login status is not ready
    if (!config || !router.isReady || !isLoggedIn) return

    // Function to handle redirection logic
    const handleRedirect = async (): Promise<void> => {
      const app = theme.hcV1 ? 'hc' : 'workouts'
      // Handle new user registration logic
      await authHelper.handleNewUser(config, null, app)

      const nextPage = router.query.next as string | undefined
      const redirectPath = nextPage
        ? nextPage
        : constructRedirectPath(router.query)
      void router.push(redirectPath)
    }

    void handleRedirect()
  }, [config, router, theme.hcV1, isLoggedIn])
}

function constructRedirectPath(query: ParsedUrlQuery): string {
  // If no query parameters, return the home path
  if (Object.keys(query).length === 0) return '/'

  // Initialize an object to hold the query parameters
  const queryParams: Record<string, string | string[]> = {}
  Object.keys(query).forEach((key) => {
    const value = query[key]
    queryParams[key] = Array.isArray(value) ? value.join(',') : value || ''
  })

  const searchParams = new URLSearchParams(
    queryParams as Record<string, string>
  )
  return `/?${searchParams.toString()}`
}
