import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { addAlpha, colors } from 'fitify-ui/src/theme/colors'

import { Stack } from '../Stack'

export const StyledUnitSwitch = styled(Stack)`
  justify-content: center;
  align-items: center;
  width: min-content;
  align-self: center;
  gap: 2px;
  font-weight: bold;
  text-align: center;
  background: ${colors.Gray200};
  padding: 2px;
  border-radius: 12px;

  ${({ theme }) => {
    if (theme.digitalV2) {
      return css`
        background: none;
        color: ${addAlpha(colors.White, 0.5)};
      `
    }
    if (theme.digitalV4) {
      return css`
        background: var(--c-blue-base);
        color: var(--c-blue75);
      `
    }

    return css`
      background: ${colors.Gray200};
    `
  }}
`

export const FormSwitchLink = styled.a<{ selected?: boolean }>`
  cursor: pointer;
  white-space: nowrap;
  outline: none;
  transition:
    color 0.2s,
    background-color 0.2s;

  ${(props) =>
    (props.theme.digitalV3 || props.theme.digitalV4 || props.theme.hcV1) &&
    css`
      min-width: 60px;
      padding: 9px 5px;
      border-radius: 10px;
      font-size: 13px;
      font-style: normal;
      backdrop-filter: blur(4px);

      color: ${props.selected ? colors.Blue400 : colors.Gray600};
      background: ${props.selected ? colors.White : 'none'};
      border: ${props.selected ? `1px solid ${colors.Gray300}` : 'none'};

      &:active {
        color: ${props.selected ? colors.Blue400 : colors.Gray900};
        background: ${props.selected ? 'none' : colors.Gray300};
      }

      @media (hover: hover) {
        &:hover {
          color: ${props.selected ? colors.Blue400 : colors.Gray900};
          background: ${props.selected ? colors.White : colors.Gray300};
        }
      }
    `}

  ${(props) =>
    props.theme.digitalV4 &&
    css`
      font-weight: 500;
      color: ${props.selected ? 'var(--c-blue950)' : 'var(--c-blue75)'};
      background: ${props.selected ? 'var(--c-vibrant-green)' : 'none'};
      border: none;

      &:active {
        color: ${props.selected ? 'var(--c-blue950)' : 'var(--c-blue75)'};
        background: ${props.selected ? 'none' : 'var(--c-vibrant-green)'};
      }

      @media (hover: hover) {
        &:hover {
          color: ${props.selected ? 'var(--c-blue950)' : 'var(--c-white)'};
          background: ${props.selected
            ? 'var(--c-vibrant-green)'
            : 'var(--c-blue850)'};
        }
      }
    `}

  ${(props) =>
    props.theme.digitalV2 &&
    css`
      padding: 0 3px;
      color: ${props.selected ? colors.White : addAlpha(colors.White, 0.3)};

      &:active {
        color: ${props.selected
          ? addAlpha(colors.White, 0.6)
          : addAlpha(colors.White, 0.3)};
      }

      @media (hover: hover) {
        &:hover {
          color: ${props.selected ? colors.White : addAlpha(colors.White, 0.3)};
        }
      }
    `}
`
