import { css } from '@emotion/react'
import styled from '@emotion/styled'

import { ChipStyledProps } from './Chip'

export const StyledChip = styled.span<ChipStyledProps>`
  width: max-content;
  padding: 4px 6px;

  color: var(--c-black);
  border-radius: 6px;
  text-align: center;

  ${(props) => {
    switch (true) {
      case props.variant === 'green':
        return css`
          background: var(--c-green400);
          font-size: 12px;
          font-weight: 500;
          text-transform: uppercase;
        `
      case props.variant === 'yellow':
        return css`
          background: linear-gradient(var(--c-yellow400), var(--c-yellow450));
          font-size: 13px;
          font-weight: 600;
        `
      default:
        return null
    }
  }}
`
