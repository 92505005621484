import { css, withTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { colors } from 'fitify-ui/src/theme/colors'

import { BREAKPOINTS, StyledAsProps } from '../utils/styled'

export const Heading = withTheme(styled.h1<StyledAsProps>`
  align-self: var(--a-title-align);
  font-family: var(--f-heading);
  font-style: normal;
  line-height: 120%;
  letter-spacing: 0.01em;
  margin: 0;
  padding: 0;

  color: var(--c-black);
  font-weight: 500;

  ${(props) => {
    switch (true) {
      case props.theme.hcV1:
        return css`
          font-size: 1.25rem;

          & > em {
            font-style: normal;
            background: ${colors.Green400};
            border-radius: 4px;
            padding: 0 3px 3px 3px;
            margin-right: 0;
          }

          b {
            color: var(--c-black);
          }
        `
      case props.theme.digitalV2:
        return css`
          color: var(--c-white);
          font-size: 1.875rem;
          font-weight: 800;

          b {
            font-weight: 800;
            color: var(--c-vibrant-green);
          }

          @media ${BREAKPOINTS.MD.lte} {
            font-weight: 700;
            padding: 0;
          }
        `
      case props.theme.digitalV3:
        return css`
          font-size: 1.5rem;
        `
      case props.theme.digitalV4:
        return css`
          color: var(--c-white);
          font-size: 2rem;
          font-weight: 700;

          @media ${BREAKPOINTS.MD.lte} {
            font-size: 1.5rem;
          }
        `
      default:
        return null
    }
  }}
`)

export const Text = styled.p<StyledAsProps>`
  color: ${(props) =>
    props.color ? `var(--c-${props.color})` : 'var(--c-text)'};
  font-weight: ${(props) => (props.weight ? props.weight : 'normal')};
  text-align: ${(props) => (props.textAlign ? props.textAlign : 'inherit')};
  text-transform: ${(props) =>
    props.textTransform ? props.textTransform : 'inherit'};
  white-space: pre-line;

  margin: 0;
  padding: 0;

  ${(props) => {
    switch (props.size) {
      case 'xs':
        return css`
          font-family: var(--f-text);
          font-size: 0.875rem;
          line-height: 120%;
        `
      case 'sm':
        return css`
          font-family: var(--f-text);
          font-size: 1.125rem;
          line-height: 120%;
        `
      case 'md':
        return css`
          font-family: var(--f-text);
          font-size: 1.25rem;
          line-height: 120%;
        `
      case 'lg':
        return css`
          font-family: var(--f-text);
          font-size: 1.5rem;
          line-height: 100%;
        `
      case 'xl':
        return css`
          font-family: var(--f-heading);
          font-size: 2rem;
          line-height: 120%;
          @media ${BREAKPOINTS.MD.lte} {
            font-size: 24px;
          }
        `
      case 'error-title':
        return css`
          font-family: var(--f-heading);
          font-size: 5.5rem;
          line-height: 120%;
        `

      default:
        return css`
          font-family: var(--f-text);
          font-size: 1rem;
          line-height: 120%;
        `
    }
  }}

  b {
    font-weight: 600;
    color: var(--c-text);
  }

  del,
  strike {
    color: var(--c-blue400);
  }
`

export const UnderlineLink = styled.a`
  text-decoration: underline;
  color: inherit;

  &:hover,
  &:active,
  &:visited {
    color: inherit;
  }
`

export const SubscriptionQuestionHeading = withTheme(styled.h3<StyledAsProps>`
  font-family: 'Graphik LCG Web';
  color: var(--c-black);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 120%;
  padding: 0;
  text-align: center;
  margin: 0 0 24px 0;
`)

export const PitchText = styled(Text)`
  flex: 1;
  ${(props) => {
    switch (true) {
      case props.theme.digitalV2:
        return css`
          text-align: center;
          color: var(--c-white);

          b {
            color: var(--c-green500);
          }
        `
      case props.theme.digitalV4:
        return css`
          text-align: center;
          color: var(--c-blue75);
          b {
            color: var(--c-vibrant-green);
          }
        `
      default:
        return css`
          color: var(--c-gray700);

          b {
            color: var(--c-black);
          }
        `
    }
  }}
`

export const SubscriptionCountdownText = styled.p<{ color?: string }>`
  font-family: var(--f-heading);
  font-size: 24px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0em;
  text-align: center;

  color: ${(props) =>
    props.color ? `var(--c-${props.color})` : 'var(--c-black)'};
`

export const SubscriptionDescriptionTextBox = styled.div`
  position: relative;
  padding: 1rem;
  background-color: var(--c-white);
  border-radius: 12px;

  @media ${BREAKPOINTS.MD.gte} {
    &::after {
      content: '';
      position: absolute;
      top: -20px;
      right: 15%;
      border-width: 10px;
      border-style: solid;
      border-color: transparent transparent #fff transparent;
    }
  }
`

export const SubscriptionDescriptionText = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: ${(props) =>
    props.theme.digitalV4 ? 'var(--c-blue75)' : 'var(--c-text)'};

  b {
    font-weight: ${(props) => (props.theme.digitalV2 ? 700 : 500)};
    color: var(--c-text);
  }
`

export const StyledAuthDescriptionText = styled(Text)`
  ${({ theme }) => {
    if (theme.digitalV2) {
      return css`
        text-align: center;
        color: var(--c-blue250);
      `
    }
    if (theme.digitalV4) {
      return css`
        color: var(--c-blue75);
      `
    }

    return css`
      color: var(--c-gray700);
    `
  }}
`
