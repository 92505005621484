export const MASS_KG_LBS_COEFFICIENT = 2.2046
export const DISTANCE_M_TO_MILE_COEFICIENT = 0.000621371192
export const INCHES_TO_CM_COEFFICIENT = 2.54

export const kilogramsToPounds = (weight: number): number => {
  return weight * MASS_KG_LBS_COEFFICIENT
}

export const poundsToKilograms = (weight: number, precision = 1): number => {
  return (
    Math.round((weight / MASS_KG_LBS_COEFFICIENT) * 10 ** precision) /
    10 ** precision
  )
}

export const inchesToCentimeters = (height: number): number => {
  return Math.round(height * INCHES_TO_CM_COEFFICIENT)
}

export const metersToMiles = (distance: number): number => {
  return distance * 0.000621371192
}

export const metersToKm = (distance: number): number => {
  return distance / 1000
}

export const roundWeightToNearestHalf = (weight?: number) => {
  if (!weight) {
    return 0
  }
  const roundedWeight = Math.round(weight * 2) / 2
  return Number.isInteger(roundedWeight)
    ? roundedWeight
    : parseFloat(roundedWeight.toFixed(1))
}

export const roundDistanceToNearestTenth = (distance?: number) => {
  if (!distance) {
    return 0
  }

  const roundedDistance = Math.round(distance * 10) / 10
  return Number.isInteger(roundedDistance)
    ? roundedDistance
    : parseFloat(roundedDistance.toFixed(1))
}
