import {
  IPlanPrices,
  PlanInterval,
  SubscriptionPlan,
} from 'fitify-ui-onboarding/src/types/subscription'

export function formatCurrency(
  value: number,
  currency: string,
  locale: string,
  fractionDigits?: number
) {
  return new Intl.NumberFormat(locale, {
    style: 'currency',
    minimumFractionDigits: 0,
    maximumFractionDigits: fractionDigits || 0,
    currency,
  }).format(value)
}

/*
    PLAN PRICE
*/
export function getPlanPrice(plan?: SubscriptionPlan): IPlanPrices | null {
  if (!plan || !plan.currency || !plan.locale) return null

  const isYearDuration = plan.discountDurationUnit === PlanInterval.YEAR
  const durationMonths = isYearDuration ? 12 : plan.discountDurationLength || 1

  const isYearInterval = plan.intervalUnit === PlanInterval.YEAR
  const intervalMonths = isYearInterval ? 12 : plan.intervalLength || 1

  const price = plan?.totalValue || 0
  const monthlyPrice = price / durationMonths

  return {
    current: formatCurrency(monthlyPrice, plan.currency, plan.locale, 2),
    total: formatCurrency(price, plan.currency, plan.locale, 2),
    original: formatCurrency(
      plan.priceTotalValue || 0,
      plan.currency,
      plan.locale,
      2
    ),
    discount: plan.discountPercentValue,
    discountDuration: plan.discountDurationLength && durationMonths,
    discountDurationUnit: plan.discountDurationUnit,
    discountInterval: intervalMonths,
    discountIntervalUnit: plan.intervalUnit,
    variant: plan.type,
  }
}

/*
    PLAN PRICE
*/
export function getWeeklyPlanPrice(
  plan?: SubscriptionPlan
): IPlanPrices | null {
  if (!plan || !plan.currency || !plan.locale) return null

  const planIntervalInMonths = plan.intervalLength || 1
  const durationWeeks =
    plan.interval === PlanInterval.YEAR ? 52 : planIntervalInMonths * 4

  const originalPrice = plan.priceTotalValue || 0
  const price = plan.totalValue || 0

  let discountValue = plan.discountPercentValue
  let weeklyPrice = price / durationWeeks

  if (price === 0) {
    weeklyPrice = originalPrice / durationWeeks
    discountValue = originalPrice
  }

  return {
    current: formatCurrency(weeklyPrice, plan.currency, plan.locale, 2),
    total: formatCurrency(price, plan.currency, plan.locale, 2),
    original: formatCurrency(
      originalPrice / durationWeeks,
      plan.currency,
      plan.locale,
      2
    ),
    discount: discountValue,
    discountDuration: plan.discountDurationLength,
    discountDurationUnit: plan.discountDurationUnit,
    variant: plan.type,
  }
}

export const resolveMonthlyPrice = (
  planType: PlanInterval,
  planPrice: number,
  duration: number
) => {
  switch (planType) {
    case PlanInterval.QUARTER || (PlanInterval.MONTH && duration === 3):
      return Math.round((planPrice / 3) * 10) / 10
    case PlanInterval.HALFYEAR || (PlanInterval.MONTH && duration === 6):
      return Math.round((planPrice / 6) * 10) / 10
    case PlanInterval.YEAR:
      return Math.round((planPrice / 12) * 10) / 10
    default:
      return planPrice
  }
}

export const resolvePercentageSaved = (
  planType: PlanInterval,
  price: number,
  regularMonthlyPrice: number | undefined,
  duration: number
) => {
  return Math.round(
    100 -
      (resolveMonthlyPrice(planType, price, duration) /
        (regularMonthlyPrice || 1)) *
        100
  )
}
