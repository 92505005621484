import { UnitSystem } from 'fitify-types/src/types/common'
import { User } from 'fitify-types/src/types/user'
import { CM_PER_INCH, KG_PER_LBS } from 'fitify-ui-onboarding/src/utils'
import {
  clearItem,
  getItem,
  setItem,
} from 'fitify-ui-onboarding/src/utils/storage'
import { createContext, useEffect, useState } from 'react'

export type IDataState = {
  gender?: User.Profile.Gender
  displayName?: string
  email?: string
  goal?: User.Profile.Goals
  body_type?: User.Profile.BodyType
  problem_areas?: User.Profile.ProblemArea[]
  previous_experience?: User.Profile.PreviousExperience
  leading_sleep_better?: boolean
  leading_improve_health?: boolean
  leading_more_relaxed?: boolean
  fitness_time_consuming?: boolean
  confident?: boolean
  procrastination?: boolean
  feel_support?: boolean
  loud_exercises?: User.Profile.LoudExercises
  fitness?: User.Profile.Fitness
  age?: number
  units?: UnitSystem
  height?: number
  weight?: number
  goal_weight?: number
  max_impact?: User.Profile.MaxImpact
  typical_day?: User.Profile.TypicalDay
  workout_frequency?: User.Profile.WorkoutFrequency
  workout_environment?: User.Profile.WorkoutEnvironment[]
  workout_length?: User.Profile.WorkoutLength
  push_up_count?: User.Profile.PushUpCount
  walking_duration?: User.Profile.WalkingDuration
  bad_habits?: User.Profile.BadHabit[]
  energy_level?: User.Profile.EnergyLevel
  latest_ideal_weight?: User.Profile.LatestIdealWeight
  sleep_duration?: User.Profile.SleepDuration
  stress?: User.Profile.Stress
  water_intake?: User.Profile.WaterIntake
  motivation?: User.Profile.Motivation[]
  motivation_level?: User.Profile.MotivationLevel
  commitment?: User.Profile.Commitment
  plan_pace?: User.Profile.PlanPace
  workout_days?: User.Profile.WorkoutDays[]
}

export const loadingSymbol = Symbol('loading')

export const DataContext = createContext<{
  data: IDataState & { [loadingSymbol]?: true }
  set: (payload: IDataState) => void
  clear: () => void
}>({
  data: {},
  set: () => void 0,
  clear: () => void 0,
})

export function useAppState() {
  const [isLoading, setIsLoading] = useState(true)
  const [data, setData] = useState<IDataState & { [loadingSymbol]?: true }>({
    [loadingSymbol]: true,
  })

  useEffect(() => {
    try {
      const planSession = getItem('session', '@fitify/plan')

      if (planSession) {
        const plan = JSON.parse(planSession)
        setData(plan)
      }
    } catch (err) {
      console.error(err)
    }
    setIsLoading(false)
  }, [])

  const set = (newValue: IDataState) => {
    const planSession = getItem('session', '@fitify/plan')
    const storeData = planSession ? JSON.parse(planSession) : data

    return setData(() => {
      // convert units if necessary
      const result = { ...storeData, ...newValue }
      const unitKeys: (keyof Pick<
        IDataState,
        'weight' | 'goal_weight' | 'height'
      >)[] = ['weight', 'goal_weight', 'height']

      if (
        unitKeys.some((key) => result[key] !== null) &&
        result.units === null
      ) {
        throw Error('Need to provide an unit first before setting a value')
      }

      if (result.units && storeData.units && result.units !== storeData.units) {
        const unchangedKeys = unitKeys.filter(
          (key) => storeData[key] !== null && result[key] === storeData[key]
        )

        for (const key of unchangedKeys) {
          let value = result[key]
          if (value !== null) {
            switch (key) {
              case 'height': {
                if (result.units === UnitSystem.Metric) {
                  value *= CM_PER_INCH
                } else if (result.units === UnitSystem.Imperial) {
                  value /= CM_PER_INCH
                }

                break
              }
              case 'weight':
              case 'goal_weight': {
                if (result.units === UnitSystem.Metric) {
                  value *= KG_PER_LBS
                } else if (result.units === UnitSystem.Imperial) {
                  value /= KG_PER_LBS
                }
                break
              }
              default:
                void 0
            }

            result[key] = Math.round(value)
          }
        }
      }

      // save to session storage if available
      setItem('session', '@fitify/plan', JSON.stringify(result))

      return result as IDataState & { [loadingSymbol]?: true }
    })
  }

  const clear = () => {
    clearItem('session', '@fitify/plan')
    clearItem('local', '@fitify/subscription/activePromo')

    setData({})
  }

  return { data, set, clear, isLoading }
}
