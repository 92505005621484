import { Identify, identify } from '@amplitude/analytics-browser'
import { useTheme } from '@emotion/react'
import { getAnalytics, setUserId, setUserProperties } from 'firebase/analytics'
import { SignInMethod, type User } from 'firebase/auth'
import { logEvent } from 'fitify-ui/src/utils/analytics'
import { IUserProfile } from 'fitify-ui-onboarding/src/api'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import TagManager from 'react-gtm-module'
import smartlookClient from 'smartlook-client'

import { IRouteType } from '../types'
import { filterUndefinedValues } from '../utils/helpers'
import { getThemeName } from '../utils/theme'

/**
 * USER PROPERTIES
 */
export function useUserProperties(
  data: Record<string, any>,
  userProfile: IUserProfile | null
) {
  useEffect(() => {
    // Early return if data do not exists
    if (!data) return

    // Define property mappings for user properties
    const propertyMappings = [
      {
        key: 'gender',
        source: ['profile.gender', 'gender'],
        map: {
          m: 'MALE',
          f: 'FEMALE',
          u: 'UNKNOWN',
          n: 'NON_BINARY',
        },
      },
      { key: 'age', source: ['profile.age', 'age'] },
      { key: 'weight', source: ['profile.weight', 'weight'] },
      { key: 'height', source: ['profile.height', 'height'] },
      {
        key: 'goal',
        source: ['profile.goal', 'goal'],
        map: {
          1: 'LOSE_FAT',
          2: 'GET_FITTER',
          3: 'GAIN_MUSCLE',
        },
      },
      {
        key: 'fitness',
        source: ['profile.fitness', 'fitness'],
        map: {
          1: 'NOT_VERY_FIT',
          2: 'FIT',
          3: 'VERY_FIT',
        },
      },
      { key: 'max_impact', source: ['profile.max_impact', 'max_impact'] },
      { key: 'ability_strength', source: ['ability.strength'] },
      { key: 'ability_cardio', source: ['ability.cardio'] },
      { key: 'ability_flexibility', source: ['ability.flexibility'] },
      { key: 'registered', source: ['registered.toMillis()'] },
      { key: 'pro', source: ['subscription.active'] },
      { key: 'plus', source: ['subscription_hc.active'] },
    ] as {
      key: string
      source: string[]
      map?: Record<string | number, any>
    }[]

    // Initialize an object to store user properties
    const userProperties: Record<string, any> = {}

    // Process property mappings
    for (const { key, source, map } of propertyMappings) {
      for (const prop of source) {
        const userProfileValue = prop.split('.').reduce((obj: any, key) => {
          const value = map ? map[obj?.[key]] : obj?.[key]
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return value
        }, userProfile)
        const dataValue = prop.split('.').reduce((obj, key) => {
          const value = map ? map[obj?.[key]] : obj?.[key]
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          return value
        }, data)

        if (userProfileValue) {
          userProperties[key] = userProfileValue
          break
        }

        if (dataValue) {
          userProperties[key] = dataValue
          break
        }
      }
    }

    // Filter out undefined properties and check if there are properties to track
    const filteredUserProperties = filterUndefinedValues(userProperties)
    const identifyEvent = new Identify()

    // Set user properties in firebase and smartlook
    setUserProperties(getAnalytics(), filteredUserProperties)
    smartlookClient.properties(filteredUserProperties)

    // Set user properties in amplitude using batch set
    for (const key in filteredUserProperties) {
      if (Object.prototype.hasOwnProperty.call(filteredUserProperties, key)) {
        const value = filteredUserProperties[key]
        identifyEvent.set(key, value)
      }
    }

    // Identify user in amplitude
    identify(identifyEvent)
  }, [data, userProfile])
}

/**
 * AUTH EVENTS
 */
export function useAuthEvent(user: User | null) {
  // Set user id and identify user in smartlook and amplitude
  const setUserIdAndIdentifyUser = (analytics: any, authUser: User) => {
    const identifyObj = new Identify()
    identify(identifyObj, {
      user_id: authUser.uid,
    })

    smartlookClient.identify(authUser.uid, {
      name: authUser.displayName ?? '',
      email: authUser.email as string,
    })

    setUserId(analytics, authUser.uid)
  }

  const pushUserDataToGTM = (user: User) => {
    const facebookProviderData = user.providerData.filter(
      (data) => data.providerId === SignInMethod.FACEBOOK
    )

    const facebookLoginID = facebookProviderData.length
      ? facebookProviderData[0].uid
      : null

    const tagManagerArgs = {
      dataLayer: {
        user_data: {
          ...{
            email_address: user.email,
            address: {
              first_name: user.displayName,
            },
            external_id: user.uid,
          },
          ...(facebookLoginID ? { fb_login_id: facebookLoginID } : {}),
        },
      },
    }

    TagManager.dataLayer(tagManagerArgs)
  }

  // Handle new user with analytics and redirect
  const handleNewUserWithAnalytics = (authUser: User) => {
    if (!authUser) return

    // Set user id and identify user in smartlook
    const userProvider = authUser.providerData[0].providerId
    setUserIdAndIdentifyUser(getAnalytics(), authUser)
    logEvent('signup', { provider_id: userProvider })
  }

  useEffect(() => {
    if (!user) return

    pushUserDataToGTM(user)
    handleNewUserWithAnalytics(user)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])
}

/**
 * PAGE EVENTS
 */
export function usePageEvent(routes: IRouteType<string>[]) {
  const router = useRouter()
  const path = router.pathname
  const page = routes.find((page) => page.path === path)
  const theme = useTheme()

  useEffect(() => {
    if (!page) return
    const pageEvent = page.event

    if (pageEvent) {
      if (typeof pageEvent === 'string') {
        logEvent(pageEvent)
      } else {
        if (pageEvent.params && pageEvent.params.theme) {
          logEvent(pageEvent.name, {
            theme: getThemeName(theme),
          })
        }
      }
    }
  }, [page, theme])
}

/**
 * Logs an answer event with transformed parameters.
 */
export function logAnswerEvent(page: IRouteType<string>, params: any): void {
  if (!page || !page.event || !page.key) return

  // Determine if it's a multi-select
  const isMultiSelect: boolean =
    typeof params !== 'string' &&
    (Array.isArray(params) || Object.keys(params).length > 1)

  // Transform the parameters
  const transformedParams: Record<string, string | number> = isMultiSelect
    ? transformParams(params)
    : { [page.key]: transformParam(params) }

  logEvent(
    typeof page.event === 'string' ? page.event : page.event.name + '_answer',
    transformedParams
  )
}

/**
 * Transforms a single parameter value.
 */
function transformParam(param: string | number | boolean): string | number {
  return typeof param === 'boolean' ? (param ? 1 : 0) : param
}

/**
 * Transforms an object containing multiple parameters.
 */
function transformParams(params: any): Record<string, string | number> {
  const transformedParams: Record<string, string | number> = {}

  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key)) {
      transformedParams[key] = transformParam(params[key])
    }
  }

  return transformedParams
}
