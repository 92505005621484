import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { addAlpha, colors } from 'fitify-ui/src/theme/colors'

import { Stack } from '../Stack'

export const StyledImperialWrapper = styled(Stack)`
  label {
    width: calc(50% - 0.5rem);
  }
`

export const FormLabel = styled.label<{ invalid?: boolean }>`
  border: none;
  padding: 0;
  margin: 0;

  font-size: 30px;
  font-weight: bold;

  width: 100%;
  height: var(--h-input-height);

  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  flex-wrap: nowrap;
  border-radius: 12px;

  border: solid var(--c-input-border);
  border-width: ${(props) => (props.theme.digitalV4 ? '2px' : '1px')};
  transition: all 0.2s;

  &:focus-within {
    background: transparent;
    border-color: var(--c-input-border-focus);
  }

  & legend {
    padding-right: 18px;
    color: var(--c-gray500);
    font-size: 14px;

    & + input {
      text-indent: 32px;
    }

    ${({ theme }) => {
      if (theme.digitalV2) {
        return css`
          color: ${addAlpha(colors.White, 0.3)};
        `
      }

      if (theme.digitalV4) {
        return css`
          font-weight: 500;
          color: var(--c-blue550);
        `
      }

      return css`
        color: var(--c-gray500);
      `
    }}
  }

  ${(props) => {
    if (props.invalid) {
      return css`
        &:not(:focus-within) {
          border-color: var(--c-input-border-error);
        }
      `
    }
    return null
  }}
`

export const StyledFormInput = styled.input`
  width: 100%;
  padding: 0;

  background: none;
  border: none;
  font-family: var(--f-text);
  font-size: 100%;
  font-weight: var(--f-input-font-weight);
  text-align: center;
  outline: none;
  color: var(--c-input-text-color);

  &::placeholder {
    color: var(--c-input-placeholder-color);
  }
`
