import { useTheme } from '@emotion/react'
import { useEffect, useState } from 'react'

import { useAuth } from './useAuth'

export const usePromoEligibilityCheck = () => {
  const { user } = useAuth()
  const theme = useTheme()
  const [isUserEligible, setIsUserEligible] = useState<boolean>(true)

  //Check user's elibility to use promo
  useEffect(() => {
    const checkEligibility = async () => {
      const response = await fetch(
        `/api/check-user-promo-eligibility?userId=${user?.uid}`
      )
      const { isEligible } = await response.json()
      setIsUserEligible(isEligible)
    }

    if (theme.hcV1 && user?.uid) {
      void checkEligibility()
    }
  }, [theme.hcV1, user?.uid])

  return isUserEligible
}
